<template>

</template>

<script>
export default {
    name: "test2",
    mounted() {
        // document.location.replace(localStorage.getItem('test_article_url'))
        document.location.replace(localStorage.getItem('test_article_url'))
    },
}
</script>

<style scoped>

</style>
